































































































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { SearchViewModel } from '../../viewmodels/search-viewmodel'

@Observer
@Component({
  components: {
    tag: () => import('../tag.vue'),
    'related-tag': () => import('@/components/tag.vue'),
    'post-list': () => import('../post-list.vue'),
    'post-grid': () => import('../post-grid.vue'),
    'user-card': () => import('../user-card.vue'),
    'dao-card': () => import('@/modules/community/common/components/dao-card.vue'),
    'trending-card': () => import('@/modules/community/home/components/review-components/trending-card.vue'),
  },
})
export default class AllSearch extends Vue {
  @Inject({}) vm!: SearchViewModel
}
